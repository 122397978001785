<template>
  <div ref="myDiv" class="relative my-28">
    <div class="absolute right-16 left-16 -top-9 border-t-[1px] border-gray02-100 dark:border-gray02-20"></div>
    <button class="arrow cursor-pointer absolute left-0 -top-14 hover:text-primary"
      @click="useLeftScroll(divWidth, 'scroll-best-authors')">
      <IconsArrowLeftCarousel />
    </button>
    <div v-if="bestAuthors.length"
      class="scroll-best-authors no-scrollbar flex flex-nowrap scroll-smooth w-full overflow-x-auto md:overflow-hidden space-x-5">
      <div v-for="item in bestAuthors" :key="item.id" class="carousel-card">
        <NuxtLink :to="`/authors/${item.id}`">
          <div class="author-info">
            <BaseAvatar :image="item.avatar" :imageAlt="`${item.first_name} ${item.last_name}`" />

            <div class="flex flex-col justify-center">
              <NuxtLink :to="`/authors/${item.id}`">
                <span class="cursor-pointer text-sm dark:text-gray02-20">
                  {{ item.first_name }} {{ item.last_name }}
                </span>
              </NuxtLink>
              <div class="location">
                <!-- <span v-if="item.role" class="mr-1 capitalize">{{ useDataConversion(item.role) }} |</span>
                <span v-if="!item.role" class="mr-1 capitalize">Роль не определена |</span> -->
                <span v-if="item.location" class="text-gray-400 flex-wrap">{{ item.location }}</span>
              </div>
            </div>
          </div>
          <div v-if="getAuthorsPictures(item.id).check" class="images">
            <div v-for="img in getAuthorsPictures(item.id).result" :key="img.id">
              <NuxtLink :to="`/gallery/${img.id}`">
                <nuxt-img :src="img.file" :alt="img.name" class="image pointer" />
              </NuxtLink>
            </div>
          </div>
          <div v-else class="images">
            Нет работ
          </div>
        </NuxtLink>
      </div>
    </div>
    <button class="arrow cursor-pointer absolute right-0 -top-14 hover:text-primary"
      @click="useRightScroll(divWidth, 'scroll-best-authors')">
      <IconsArrowRightCarousel />
    </button>
  </div>
</template>

<script setup>
import { useLeftScroll, useRightScroll } from "../../hooks/useCarouselScroll";
import { useDataConversion } from "~/hooks/useDataConversion";
import { useHomePageData } from '~/stores/homeData';
import { usePicturesStore } from '~/stores/pictures';
import { storeToRefs } from 'pinia';
const storeHomeData = useHomePageData();
const storePictureStore = usePicturesStore();
const { best } = storeToRefs(storeHomeData);
const { pictures } = storeToRefs(storePictureStore);
const bestAuthors = ref([]);
const myDiv = ref(null);
const authorsPictures = ref([]);
const viewPictures = ref(false);
let divWidth = 0;

if (best.value && Array.isArray(best.value)) {
  bestAuthors.value = best.value;
}

onMounted(() => {
  updateDivWidth();
  window.addEventListener('resize', updateDivWidth);
  if (pictures.value.length) {
    authorsPictures.value = pictures.value;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateDivWidth);
});

function updateDivWidth() {
  if (myDiv.value) {
    divWidth = myDiv.value.offsetWidth / 2;
  }
};

function getAuthorsPictures(id) {
  if (authorsPictures.value.length) {
    const result = authorsPictures.value.filter(el => el.author.id === id).slice(0, 4);
    return {
      result: result,
      check: true,
    };
  }
  return { check: false };
};
</script>

<style scoped>
.carousel-card {
  @apply
  mx-2
  my-10
  min-w-full
  sm:min-w-[334px]
  md:min-w-[300px]
  lg:min-w-[380px]
  overflow-hidden
}

.author-info {
  @apply
  flex
  flex-row
  space-x-4
  sm:space-x-5
  mb-4
  min-h-[45px]
}

.location {
  @apply
  text-xs
  sm:text-sm
  font-thin
}

.images {
  @apply
  flex
  space-x-2
  border-t
  border-gray-900
  pt-5
  overflow-hidden
}

.image {
  @apply
  bg-gray-50
  w-[70px]
  hover:opacity-80
  min-h-6
  text-xs
  text-gray-200
}

.arrow {
  @apply
  dark:text-primary-dark
  hover:text-primary-light
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>