<template>
  <div class="banner">
    <div class="scroll-banners flex flex-nowrap scroll-smooth w-full overflow-x-auto no-scrollbar">
      <div
          v-for="(banner, index) in banners"
          :key="banner.id"
          class="banner-item fade-in"
          v-show="index === currentIndex"
      >
        <div class="banner-image-wrapper">
          <nuxt-img
              :src="banner.image"
              :alt="banner.title"
              class="image"
          />
        </div>

        <div class="banner-content">
          <div class="banner-content-container">
            <div>
              <div class="flex flex-col xs:flex-row">
                <span class="title">{{ banner.title }}</span>
                <span class="desc">{{ banner.desc }}</span>
              </div>

              <span class="count">{{ index + 1 }}/{{ total }}</span>
            </div>

            <div class="button">
              <UiSecondaryButton
                  :text="auth ? 'Разместить работу' : 'Зарегистрироваться'"
                  @click="() => { auth ? showModal = true : useRouter().push('/registration') }"
                  class="banner-button"
              />
            </div>
          </div>

          <div class="arrows">
            <!-- LEFT -->
            <button class="arrow arrow-left" @click="useLeftScroll('scroll-banners')">
              <IconsArrowLeftCarousel />
            </button>
            <!-- LINE -->
            <div class="absolute right-24 left-24 bottom-4 mb-1 border-t border-gray-400"></div>
            <!-- RIGHT -->
            <button class="arrow arrow-right" @click="useRightScroll('scroll-banners')">
              <IconsArrowRightCarousel />
            </button>
          </div>
        </div>


      </div>
    </div>
    <BaseModal
        v-model:show="showModal"
        :textH3="'Загрузите свою работу и заполните обязательные поля'"
    >
      <UiSlotOfTheModalFileUpload
          v-model:message="showMessageUpload"
          v-model:show="showModal"
      />
    </BaseModal>
    <BaseModal
        v-model:show="showMessageUpload"
        :textH3="'Работа успешно загружена'"
    />
  </div>
</template>

<script setup>
import { useUserData } from '~/stores/userData';
import { useHomePageData } from '../../stores/homeData';
import { storeToRefs } from 'pinia';

const store = useUserData();
const storeHomeData = useHomePageData();
const { authenticated } = storeToRefs(store);
const auth = authenticated;
const showModal = ref(false);
const showMessageUpload = ref(false);
const banners = ref([]);
const total = ref(0);
let currentIndex = ref(0);
let touchStartX = 0;
let touchEndX = 0;
let autoSwitchInterval = null;

if (storeHomeData.banner) {
  banners.value = storeHomeData.banner;
  total.value = storeHomeData.banner.length;
}

function switchBanner() {
  if (currentIndex.value < banners.value.length - 1) {
    currentIndex.value++;
  } else {
    currentIndex.value = 0;
  }
}

function startBannerAutoSwitch() {
  stopBannerAutoSwitch();
  autoSwitchInterval = setInterval(switchBanner, 3000);
}

function stopBannerAutoSwitch() {
  if (autoSwitchInterval) {
    clearInterval(autoSwitchInterval);
    autoSwitchInterval = null;
  }
}

function onTouchStart(event) {
  touchStartX = event.touches[0].clientX;
};

function onTouchEnd(event) {
  touchEndX = event.changedTouches[0].clientX;

  if (touchEndX < touchStartX) {
    useRightScroll('scroll-banners');
  } else if (touchEndX > touchStartX) {
    useLeftScroll('scroll-banners');
  }
};

onMounted(() => {
  startBannerAutoSwitch();
  const container = document.querySelector('.scroll-banners');
  if (container) {
    container.addEventListener('touchstart', onTouchStart);
    container.addEventListener('touchend', onTouchEnd);
  } 
});

function useLeftScroll(classContainer) {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  } else {
    currentIndex.value = banners.value.length - 1;
    const container = document.querySelector(`.${classContainer}`);
    container?.scrollTo({ left: container.scrollWidth, behavior: "smooth" });
  }
  startBannerAutoSwitch();
}

function useRightScroll(classContainer) {
  if (currentIndex.value < banners.value.length - 1) {
    currentIndex.value++;
  } else {
    currentIndex.value = 0;
    const container = document.querySelector(`.${classContainer}`);
    container?.scrollTo({ left: 0, behavior: "smooth" });
  }
  startBannerAutoSwitch();
}

onUnmounted(() => {
  stopBannerAutoSwitch();
  const container = document.querySelector('.scroll-banners');
  if (container) {
    container.removeEventListener('touchstart', onTouchStart);
    container.removeEventListener('touchend', onTouchEnd);
  }
});
</script>

<style scoped>
.banner {
  @apply
  relative
  bg-primary-light
  text-white
}

.banner-item {
  @apply
  min-w-full
  overflow-hidden
}

.banner-image-wrapper {
  @apply
  bg-[#002DB3]
  h-[120px]
  md:h-[237px]
  lg:h-[315px]
}

.banner-image-wrapper > .image {
  @apply
  w-full
  h-full
  object-cover
}

.banner-content {
  @apply
  relative
  flex
  flex-col
  pb-20
  ml-5
  mr-6
  md:ml-10
  md:mr-10
  mt-8
  h-[400px]
  xxxs:h-[460px]
  md:h-[460px]
  lg:h-[355px]
}
.banner-content-container{
  @apply
  flex
  flex-col
  h-full
  justify-between
}

.banner-content .title {
  @apply
  mr-16
  xs:w-full
  font-semibold
  text-2xl
  xxs:text-3xl
  xs:text-4xl 
  break-words
}

.title::first-letter {
  @apply
  uppercase
}

.banner-content .desc {
  @apply
  hidden
  w-full
  text-xs
  font-light
  mt-5
  xs:w-1/3
  xs:mt-0
  xs:mr-28
  xs:-right-10
}

.banner-content .count {
  @apply
  absolute
  top-0
  right-0
  text-sm
  font-light
}

.banner-content .banner-button {
  @apply
  mt-6
  z-50
}

.banner-content .arrows {
  @apply
  absolute
  right-0
  bottom-0
  left-0
  flex
  justify-between
  mb-3
}

.arrow {
  @apply
  cursor-pointer
  text-gray-300
  hover:text-white
  z-50
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
