<template>
  <div class="relative my-28">
    <div class="absolute right-16 left-16 -top-9 border-t-[1px] border-gray02-100 dark:border-gray02-20"></div>
    <button class="arrow cursor-pointer absolute left-0 -top-14"
            @click="useLeftScroll(divWidth, 'scroll-top')">
      <IconsArrowLeftCarousel />
    </button>
    <div ref="myDiv" class="scroll-top no-scrollbar flex flex-nowrap scroll-smooth w-full overflow-x-auto md:overflow-hidden space-x-5">
      <div
          v-for="image in imagesList"
          :key="image.id"
          class="carousel-card"
      >
        <NuxtLink :to="`/gallery/${image.id}`">
          <nuxt-img
              :src="image.file"
              :alt="image.name"
              class="image"
          />
        </NuxtLink>
        <div class="mt-5">
          <h3 class="title">
            {{ image.name }}
          </h3>
          <p class="description">
            <span class="font-medium text-gray-900">{{ image.author.first_name}} {{ image.author.last_name }}</span></p>
        </div>
      </div>
    </div>
    <button class="arrow cursor-pointer absolute right-0 -top-14"
            @click="useRightScroll(divWidth, 'scroll-top')">
      <IconsArrowRightCarousel />
    </button>
  </div>
</template>

<script setup>
import { useLeftScroll, useRightScroll } from "../../hooks/useCarouselScroll";
import { useHomePageData } from '~/stores/homeData';
import { storeToRefs } from 'pinia';
const storeHomeData = useHomePageData();
const { top } = storeToRefs(storeHomeData);
const myDiv = ref(null);
let divWidth = 0;
const imagesList = ref([]);

if (top.value && Array.isArray(top.value)) {
  imagesList.value = top.value;
}

onMounted(() => {
  updateDivWidth();
  window.addEventListener('resize', updateDivWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateDivWidth);
});

function updateDivWidth() {
  if (myDiv.value) {
    divWidth = myDiv.value.offsetWidth / 2;
  }
}
</script>

<style scoped>
.carousel-card {
  @apply
  mx-2
  min-w-[259px]
  sm:min-w-[216px]
  md:min-w-[300px]
  lg:min-w-[380px]
  overflow-hidden
}

.image {
  @apply
  bg-gray-50
  w-full
  hover:opacity-80
  min-h-6
  text-xs
  text-gray-200
}

.title {
  @apply
  leading-5
  font-semibold
  my-5
  text-center
}

.description {
  @apply
  text-sm
  font-light
  text-center
}

.arrow {
  @apply
  dark:text-primary-dark
  hover:text-primary-light
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>