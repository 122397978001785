<template>
  <div class="container">
    <div class="flex flex-col space-y-4 md:w-1/2">
      <div class="mb-5">
        <h3 class="text-xl font-bold mb-3">Если вы:</h3>
        <p class="text-sm text-gray-500 font-light">
          Художник, куратор, галерея, музей, выставочный зал, если вы создаете или проводите выставочные проекты – присоединяйтесь к Артели!
          <!--          <a href="#" class="block text-primary my-5 hover:underline">Обратная связь</a>-->
        </p>
      </div>
    </div>
    <div class="w-full">
      <h3 class="text-xl font-bold mb-3">О проекте</h3>
      <p class="text-sm text-gray02-80 font-light dark:text-gray02-20">
        Регистрируясь в Артели вы присоединяетесь к арт сообществу, где сможете найти обширную сеть контактов среди художников, кураторов и выставочных залов.

        <br><br>
        Артель предоставляет вам инструменты для создания и развития своего портфолио и личного бренда.

        <br><br>
        Здесь у вас есть возможность писать свои посты и комментировать чужие. Так же Артель предоставляет возможность разместить опен-колл с вашим проектом, который увидят все участники Артели!

      </p>
    </div>
  </div>
</template>

<style scoped>
.container {
  @apply
  flex
  flex-col
  md:flex-row
  md:space-x-12
  justify-between
  py-12
  my-12
}
</style>