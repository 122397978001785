<template>
    <BaseLoader v-if="loader" class="inset-1/2"/>
    <section v-if="!loader && banner.length" class="section" >
      <HomeCarousel1 />
    </section>

    <section class="section">
      <div class="px-5 lg:px-10">
        <HomeWhoWeAre />
      </div>
    </section>

    <section v-if="!loader && top.length" class="section">
      <div class="px-5 lg:px-10">
        <div  class="flex flex-col sm:flex-row justify-between items-center p-1">
          <h2 class="section-title">Это топ</h2>
          <NuxtLink class="link-button" to="/gallery">Смотреть все</NuxtLink>   
        </div>       
        <HomeCarousel2 />
      </div>
    </section>

    <section v-if="!loader && best.length" class="section bg-gray02-20 dark:bg-gray-800 pt-16">
      <div class="px-5 lg:px-10 ">
        <div class="flex flex-col sm:flex-row justify-between items-center p-1">
          <h2 class="section-title">Одни из лучших</h2>
          <NuxtLink class="link-button" to="/authors">Смотреть все</NuxtLink>        
        </div>
        <HomeCarousel3 />
      </div>
    </section>

<!--    <section class="section">-->
<!--      <div class="px-5 lg:px-10">-->
<!--        <ProfileCards />-->
<!--      </div>-->
<!--    </section>-->

    <section class="section">
      <div class="px-5 lg:px-10">
        <SharedHowItWorks />
      </div>
    </section>    
</template>

<script setup>
import { useHomePageData } from '~/stores/homeData';
import { usePicturesStore } from '~/stores/pictures';
import { storeToRefs } from 'pinia';
const storeHomeData = useHomePageData();
const storePictureStore = usePicturesStore();
const { loading, top, best, banner } = storeToRefs(storeHomeData);
const loader = loading;

await storeHomeData.getBannerData();
await storeHomeData.getTopData();
await storeHomeData.getBestData();
await storePictureStore.getPictures();


useHead({
  title: "Артель",
  meta: [{ name: "description", content: "Точка сборки современного русского искусства" }]
});
</script>

<style scoped>
.section {
  @apply
  relative
  mx-auto
  max-w-7xl
  w-full
  min-h-[300px]
  lg:px-10
  mb-5
}

.section-title {
  @apply
  text-3xl
  md:text-4xl
  font-semibold
}

.link-button {
  @apply
  block
  text-xs
  text-primary-light
  dark:text-primary-dark
  my-5
  hover:underline
}

</style>
